import { useEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import RgpDropDown from "../../components/formfields/dropdown";
import { createMagicItem, userId } from "../../resources/firebase";
import LoaderModal from "../loader/loadermodal";
import './magictempopup.css';

function useOutsideAlerter(ref, toggleCallback, handleClose) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleCallback(false)
                handleClose(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const MagicItemAdd = ({ items, show, handleClose, onSubmit }) => {
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState(null);
    const [type, setType] = useState(null);
    const [rarity, setRarity] = useState(null);
    const [error, setError] = useState('');
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShowModal, handleClose);


    useEffect(() => {
        setShowModal(show)
        console.log(showModal, show)
    }, [show])

    const submitMagicItem = async (event) => {
        setError('')
        if(!name || !type || !rarity ){
            setError('All Options are required!')
            return
        }
        onSubmit({
                name: name,
                rarity: rarity,
                type: type,
                magicItem: '',
                edited: true,
                userId:userId()
            })
        handleClose(false)
    
    }

    return (
        <div ref={wrapperRef} className={"app-modal " + (showModal ? "display-block" : "display-none")} style={{ background: '#F2F4F3', height: '400px' }}>
            <img className="delete" src={require('../../resources/images/delete.png')} onClick={() => { setShowModal(false); handleClose(false); }}></img>
            <div className="modal-container" style={{ textAlign: 'center' }}>
                <div className="title">Create Magic Item</div>
                <div className="row" style={{ display: 'inline-block' }}>
                    <p className="error">{error}</p>
                    <p style={{ textAlign: 'left' }}>Name of Item:</p>
                    <div className="inputfield col-12" style={{ width: 'auto' }}><input style={{ width: '413px' }} value={name} onChange={(event) => setName(event.target.value)}></input></div> <br></br>
                    <RgpDropDown defaultvalue={'Rarity'} selectedValue={rarity} onChange={(event) => setRarity(event.target.value)} values={['Common', 'Uncommon', 'Rare', 'Very Rare', 'Legendary', 'Artifact']} classes={['col-4']}></RgpDropDown>
                    <RgpDropDown defaultvalue={'Type'} selectedValue={type} onChange={(event) => setType(event.target.value)} values={['Weapon', 'Ring', 'Wondrous Item', 'Amulet', 'Armor', 'Potion', 'Scroll', 'Wand']} classes={['col-4']}></RgpDropDown>
                </div>
                <button className="create-btn col-6" onClick={(e) => submitMagicItem(e)}>Create item</button>

                <div className="middle-ads-container ad-container" > <h2>Ad Space</h2> </div>
            </div>

        </div>
    );
}

export default MagicItemAdd