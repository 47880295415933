import { useEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import RgpDropDown from "../../components/formfields/dropdown";
import './magictempopup.css';

function useOutsideAlerter(ref, toggleCallback, handleClose) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleCallback(false)
                handleClose(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const MagicItemPopup = ({ items, show, handleClose, onSubmit }) => {
    const [showModal, setShowModal] = useState(false);
    const [isEditMode, setisEditMode] = useState(false);
    const [item, setItem] = useState(items);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShowModal, handleClose);


    useEffect(() => {
        setShowModal(show)
        setisEditMode(false)
        setItem(items)
        console.log(isEditMode)
    }, [show])

    return (
        <div ref={wrapperRef} className={"app-modal " + (showModal ? "display-block" : "display-none")}>
            <img className="delete" src={require('../../resources/images/delete.png')} onClick={() => { setShowModal(false); handleClose(false); }}></img>
            {
                isEditMode ? (<div className="modal-container">
                    <div className="title-edit">
                        <div className="first">
                            <div className="last">
                                {item?.name}
                            </div>
                        </div>
                    </div>
                    <div className="row sub-title-edit" style={{ margin: 0 }}>
                        <div className="col-6">
                            <RgpDropDown defaultvalue={'--Select Type--'} selectedValue={item?.type} onChange={(event) => setItem({...item, type:event.target.value})} values={['Weapon', 'Ring', 'Wonderous Item', 'Amulet', 'Armor', 'Potion', 'Scroll', 'Wand']}  classes={['drop']}></RgpDropDown>
                        </div>
                        <div className="col-6" style={{ textAlign: "end" }}>
                            <RgpDropDown defaultvalue={'--Select Rarity--'}  selectedValue={item?.rarity} onChange={(event) => setItem({...item, rarity:event.target.value})}  values={['Common', 'Uncommon', 'Rare', 'Very Rare', 'Legendary',  'Artifact']} classes={['drop']}></RgpDropDown>
                            
                        </div>
                    </div>
                    <div className="model-body">
                        <textarea value={item?.magicItem} onChange={(event)=>setItem({...item, magicItem:event.target.value})} className="modal-textarea" style={{ resize: "none" }}>
                            
                        </textarea>
                    </div>
                    <button className="cancel edit" onClick={() => { setShowModal(false); handleClose(false) }}>Cancel</button>
                    <button className="save edit" onClick={() => { setShowModal(false); handleClose(false); onSubmit(item)  }}>Save</button>
                </div>) :
                    <div className="modal-container">
                        <div className="title"> {item?.name}</div>
                        <div className="row sub-title">
                            <div className="col-6">Type: {item?.type}</div>
                            <div className="col-6" style={{ textAlign: "end" }}>Rarity: {item?.rarity}</div>
                        </div>
                        <div className="model-body">
                        {item?.magicItem}
                        </div>
                        <button className="edit" onClick={() => { setisEditMode(true) }}>Edit</button>
                    </div>
            }

        </div>
    );
}

export default MagicItemPopup