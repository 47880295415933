import React from "react";
import Modal from "react-bootstrap/Modal";
import { getImages, userId } from "../resources/firebase";
//create a modal to display a two-step wizard to create an NPC
export default class npcWizard extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      step: 1,
      npcName: "",
      npcImageURL: "https://firebasestorage.googleapis.com/v0/b/rpghookgen.appspot.com/o/avatars%2FSorcerer-2.png?alt=media&token=f9b22f0e-6f57-49a5-92e1-b2fd00e02ecd",
      race: "",
      class: "",
      alignment: "",
      description: "",
      images: [],
      loading: true,
      uid: userId(),
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  //load images from the storage bucket
  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };
  nextPage = () => {
    this.setState({ step: this.state.step + 1 });
  };
  previousPage = () => {
    this.setState({ step: this.state.step - 1 });
  };
  handleNpcNameChange = (event) => {
    this.setState({ npcName: event.target.value });
  };
  handleNpcImageURLChange = (event) => {
    this.setState({ npcImageURL: "https://firebasestorage.googleapis.com/v0/b/rpghookgen.appspot.com/o/avatars%2FSorcerer-2.png?alt=media&token=f9b22f0e-6f57-49a5-92e1-b2fd00e02ecd" });
  };
  handleRaceChange = (event) => {
    this.setState({ race: event.target.value });
  };
  handleClassChange = (event) => {
    this.setState({ class: event.target.value });
  };
  handleAlignmentChange = (event) => {
    this.setState({ alignment: event.target.value });
  };
  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value });
  };
  createNPC = () => {
    //create the NPC in the database
  console.log(this.state)
    fetch(`https://us-central1-rpghookgen.cloudfunctions.net/createNPC?name=${this.state.npcName}&flavorText=${this.state.description}&userId=${this.state.uid}&id=new&question=${this.state.description}&race=${this.state.race}&class=${this.state.class}&alignment=${this.state.alignment}&imageURI=${this.state.npcImageURL}`);
    //close the modal
    this.hideModal();
  };
  //load the images from the storage bucket and set the state variable to the list of images, then set loading to false
  componentDidMount() {
    getImages().then((images) => {
      images.forEach((image) => {
        image.then((url) => {
          console.log(url);
          this.setState({ images: [...this.state.images, url] });
        });
      });
      this.setState({ images: images, loading: false });
    });
  }
  /* There will be three steps to the wizard:
    1. Name, class, race, and alignment
    2. Image URL: which have a grid of images to choose from 
    3. Description: which will have a text box to enter a description
    */
  render() {
    return (
      <div>
        <button
          className="btn btn-primary"
          id="create-button"
          onClick={this.showModal}
        >
          Create NPC
        </button>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create NPC</Modal.Title>
            <button className="btn btn-primary" onClick={this.previousPage}>
              Previous
            </button>
            <button className="btn btn-primary" onClick={this.nextPage}>
              Next
            </button>
          </Modal.Header>
          <Modal.Body>
            {this.state.step === 1 && (
              <div>
                <h3>Step 1: Basic Information</h3>
                <div className="form-group">
                  <label htmlFor="npcName">NPC Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="npcName"
                    placeholder="Enter NPC Name"
                    value={this.state.npcName}
                    onChange={this.handleNpcNameChange}
                  />
                  <label htmlFor="npcRace">Race</label>
                  <input
                    type="text"
                    className="form-control"
                    id="npcRace"
                    placeholder="Enter NPC Race"
                    value={this.state.race}
                    onChange={this.handleRaceChange}
                  />
                  <label htmlFor="npcClass">Class</label>
                  <input
                    type="text"
                    className="form-control"
                    id="npcClass"
                    placeholder="Enter NPC Class"
                    value={this.state.class}
                    onChange={this.handleClassChange}
                  />
                  <label htmlFor="npcAlignment">Alignment</label>
                  <input
                    type="text"
                    className="form-control"
                    id="npcAlignment"
                    placeholder="Enter NPC Alignment"
                    value={this.state.alignment}
                    onChange={this.handleAlignmentChange}
                  />
                </div>
              </div>
            )}
            {this.state.step === 2 && (
              <div>
                <h3>Step 2: Image</h3>
                {/*Get all the images and display them as a grid*/}
                <div className="row">
                  {this.state.loading && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  {!this.state.loading &&
                    this.state.images.map((image,i) => {
                      return (
                        <div className="col-md-4">
                          <img
                            src={image}
                            alt="npc"
                            className="img-thumbnail"
                            onClick={this.handleNpcImageURLChange}
                            value={image}
                            key={i}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {this.state.step === 3 && (
              <div>
                <h3>Step 3: Description</h3>
                <div className="form-group">
                  <label htmlFor="npcDescription">Description</label>
                  <textarea
                    className="form-control"
                    id="npcDescription"
                    rows="3"
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                  ></textarea>
                </div>
                <button className="btn btn-primary" onClick={this.createNPC}>
                  Create NPC
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
