

import { useEffect, useRef, useState } from "react";
// import './magictempopup.css';

function useOutsideAlerter(ref, toggleCallback, handleClose) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            console.log(event)
            if (ref.current && !ref.current.contains(event.target)) {
                toggleCallback(false)
                handleClose(false)
            }
        }
        // Bind the event listener
        document.addEventListener("scroll", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("scroll", handleClickOutside);
        };
    }, [ref]);
}

const MagicItemList = ({ items, page, handleClose,pageNumbers=[], itemNumber,changePage }) => {
    const [showModal, setShowModal] = useState(false);
    const [pageNumber, setPageNumbers] = useState([]);
    const wrapperRef = useRef(null);
   // useOutsideAlerter(wrapperRef, setShowModal, handleClose);


    useEffect(() => {
    }, [pageNumber])

    function pageButtons() {
        const itemRows = Math.ceil(itemNumber / 12)
        let pages = []
        for (let i = 0; i <= itemRows; i++) {
            //set the pageNumbers state to an array of html elements for the page buttons
            pages.push(<li className={"page-item " + (page === i ? 'active' : '')} key={i} onClick={() => changePage(i)}><a className="page-link" >{i + 1}</a></li>);
            // console.log(this.state.pageNumbers)
        }
        // setPageNumbers(...pages)
    }


    return (
        <div ref={wrapperRef} className="row boxes">
                        {items.map((item, index) => {
                            // if (Math.floor(index / 12) === page) {
                                return (
                                    // <MagicItemCard key={item.id} page={index % 12} id={item.id} item={item} rarity={item.rarity} type={item.type} editMagicItem={this.editMagicItem} removeMagicItem={this.removeMagicItem} />
                                    <div className="col-6 magic-box" key={index}>
                                        <div className="box-title">
                                            {item.name}
                                        </div>
                                        <div className="box-sub-title">
                                            {item.type} | {item.rarity}
                                        </div>
                                        <div className="box-description">
                                            {item.magicItem ? '' : 'No Description'}
                                            {item.magicItem?.substring(item.magicItem.toLowerCase().indexOf('effects:') + 7, item.magicItem.toLowerCase().indexOf('effects:') + 150)}
                                            {/* This weapon is epic and really cool. A bunch of extra data. Something else cool. This weapon is epic and really … */}
                                        </div>
                                        <div className="open-details" onClick={()=>handleClose(item)}>

                                            <img className="share-inactive" src={require("../resources/images/share.png")} />
                                            <img className="share-active" src={require("../resources/images/share-blue.png")} />
                                        </div>
                                    </div>
                                )
                            // }
                            // else {
                            //     return null;
                            // }
                        }
                        )}
                        {/* create pagination with ul and li*/}
                        {/* <ul className="pagination">
                            <li className="page-item"><a className="page-link" onClick={() => changePage(page-1)} >Previous</a></li>
                            {pageButtons()}
                            {pageNumber}
                            <li className="page-item"><a className="page-link" onClick={() => changePage(page+1)} >Next</a></li>
                        </ul> */}
                    </div>
    );
}

export default MagicItemList