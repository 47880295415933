import React,{useState} from "react";
import './LoginSignup.css'
import { useNavigate } from "react-router-dom";
import Validation from "./LoginValidation"
import logo from "../resources/images/login-side-image.svg";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../resources/firebase";

function Login() {
    const [inputField,setInputField] = useState({
		email:"",
        password:"",
        confirmPassword:"",
	})
    const [login,setLogin] = useState(false)
    const [formName,setFormName] = useState("Sign Up")
    const navigate=useNavigate()
    const [errors,setErrors] = useState({})

    
    const inputChangeHandler=(event)=>{
        setInputField({...inputField,[event.target.name]:event.target.value})
    }

    const loginButtonHandler=async (event)=>{
        event.preventDefault()
        console.log(inputField)

        if(Object?.keys(Validation(inputField))?.length === 0){
            const {email,password} = inputField
            //if login is true then login else signup
            if(login){
            signInWithEmailAndPassword(auth,email,password).then(
                (userCredential) => {
                    const user = userCredential.user;
                    console.log(user)
                    navigate("/")
                }
            )
            }else{
                setLogin(true)
                setFormName("Login")
            }
        setErrors()
        }else{
            setErrors(Validation(inputField))
        }

    }

     const signupButtonHandler=(e)=>{
         e.preventDefault()
         //if login is false confirm the password matches the confirm password field and then signup
            if(!login){
                if(inputField.password === inputField.confirmPassword){
                    const {email,password} = inputField
                    createUserWithEmailAndPassword(auth,email,password).then(
                        (userCredential) => {
                            const user = userCredential.user;
                            console.log(user)
                            navigate("/")
                        }
                    )
                }else{
                    setErrors({confirmPassword:"Passwords do not match"})
                }
            }else{
                setLogin(false)
                setFormName("Sign Up")
            }

     }


    return (
        <>
            <div className="form_outer">
            <div className="form_inner">
                <div className="image_side">
                    <img src={logo} alt="" />
                </div>
                <div className="form_side">
                    <form className="form_group">
                        <h1>{formName}</h1>
                        <div className="form_fils">
				<input 
                type="email"
                className="form-control" 
                name="email"
                 value={inputField.email} 
                 onChange={inputChangeHandler} 
                 placeholder="Email" 
                 />
                 {errors?.email && <p style={{color:'red'}}>{errors?.email}</p>}
			</div>
			<div className="form_fils">
				<input  
                type="password"
                className="form-control" 
                name='password'
                value={inputField.password} 
                onChange={inputChangeHandler} 
                placeholder="Password" 
                />
                {errors?.password && <p style={{color:'red'}}>{errors?.password}</p>}
			</div>
            {/* if login is false then show the confirm password field */}
            {login === false && <div className="form_fils">
                <input
                type="password"
                className="form-control"
                name="confirmPassword"
                value={inputField.confirmPassword}
                onChange={inputChangeHandler}
                placeholder="Confirm Password"
                />
                {errors?.confirmPassword && <p style={{color:'red'}}>{errors?.confirmPassword}</p>}
            </div>}
                        <div className="form_btn">
                             {/*if login = true set the style of the style of the login button to active-button else set the status of the Sign up to active-button*/}
                            <button className={login ? "btn active-button" : "btn"} type="button" onClick={loginButtonHandler}>Login</button>
                            <button className={login ? "btn" : "btn active-button"} type="button" onClick={signupButtonHandler}>Signup</button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </>
    )
}
export default Login;