
import ReactDOM from 'react-dom/client';
import CreateMagicItem from './pages/createMagicItem';
import NPCGenerator from './pages/npcGenerator';
import NPCLibrary from './pages/npcLibrary';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from './pages/Layout';
import MagicItemLibrary from './pages/magicItemLibrary';
import Home from './pages/home';
import LoginSignup from './pages/LoginSignup';
import {auth} from './resources/firebase';
import {useEffect,useState} from 'react';
import {onAuthStateChanged} from 'firebase/auth';


export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  onAuthStateChanged(auth, (user) => {
  if (user) {
    setLoggedIn(true);
    setUser(user);
  } else {
    setLoggedIn(false);
    setUser(null);
  }
});


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Home />} />
          <Route path="magic-items" element={<MagicItemLibrary/>} />
          <Route path="item-library" element={ <MagicItemLibrary />} exact/>
          <Route path="item-library" element={<MagicItemLibrary />} exact/>
          <Route path="npc-generator" element={<NPCGenerator />} />
          <Route path="npc-library" element={<NPCLibrary />} />
        </Route>
        <Route path='login' element={<LoginSignup />} />
      </Routes>
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

