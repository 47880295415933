//create a react navbar component
import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { auth } from '../resources/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { useState, useEffect } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import mainLogo from '../resources/images/logo.svg';
import gear from '../resources/images/gear_icon.svg';
import gearwhite from '../resources/images/gear_icon_white.svg';
import Dropdown from 'react-bootstrap/Dropdown';

function useOutsideAlerter(ref, toggleCallback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleCallback(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


export default function NavbarComponent() {
    //set a state to check if the user is logged in
    const [loginState, setLoginState] = useState(true);
    const [toggleMenu, setToggleMenu] = useState(false);
    const [selectedOption, setSelectedOption] = useState("home");
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setToggleMenu);
    //create a function to check if the user is logged in
    useEffect(() => {
        onAuthStateChanged(auth, user => {
            if (user) {
                setLoginState(true);
            } else {
                setLoginState(true);
            }
        });
    }, [loginState]);
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            {/* create a logo */}
            <div className='logo col-md-2'>
                <img src={mainLogo} alt="logo" className="logo-img " />
                <div className="logo-text">RPGAnvil</div>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse col-md-6" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item" onClick={() => setSelectedOption("home")}>
                        <Link to="/" className={"nav-link " + (selectedOption === "home" ? "active" : "")}>Home</Link>
                    </li>

                </ul>
                {loginState ? (
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item " onClick={() => setSelectedOption("magic")}>
                            <Link to="/magic-items" className={"nav-link " + (selectedOption === "magic" ? "active" : "")}>Magic Items</Link>
                        </li>
                        <li className="nav-item" onClick={() => setSelectedOption("npcs")}>
                            <Link to="/npc-library" className={"nav-link " + (selectedOption === "npcs" ? "active" : "")}>NPCs</Link>
                        </li>
                    </ul>
                ) : (
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to="/login" className="nav-link">Login</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/register" className="nav-link">Register</Link>
                        </li>
                    </ul>
                )}
            </div>
            {loginState ? (
                <div id="menulist">
                    <div className='col-md-2'>
                        <div className='settings-sub-collpase'>
                            <img src={gear} alt="logo" onClick={() => setToggleMenu(!toggleMenu)} style={{ height: "1.5rem" }} />
                            <div className='label-color inline' style={{ paddingTo: "15px" }} onClick={() => setToggleMenu(!toggleMenu)}>User</div>
                        </div>
                    </div>
                    <div ref={wrapperRef} className={'col-md-2 active-menu collapse' + (toggleMenu ? 'show' : '')} id="menulist">
                        <div className='settings-sub'>
                            <img src={gearwhite} alt="logo" style={{ height: "1.5rem" }} />
                            <div className='label-color inline'>User</div>
                        </div>
                        <div className='settings-list'>
                            <div className='list-first-option' >Settings</div>
                            <div className='list-last-option'>Logout</div>
                        </div>
                    </div>
                </div>) : null}


        </nav>
    );
}
