import React, { Component } from "react";
import { doc, deleteDoc,collection,query,onSnapshot,where } from "firebase/firestore";
import { db, updateMagicItem, subscribeToMagicItems, createMagicItem,userId } from "../resources/firebase";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import '../resources/dnd-styles.css';
import './magicItemLibrary.css'
import MagicItemPopup from "../modals/magicitempopup/magicitempopup";
import MagicItemAdd from "../modals/magicitempopup/magicItemAdd";
import RgpDropDown from "../components/formfields/dropdown";
import LoaderModal from "../modals/loader/loadermodal";
import MagicItemList from "../components/magicItemsList";
// import InfiniteScroll from "react-infinite-scroller";

export default class magicItemLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            items_t: [],
            loggedIn: false,
            loading: true,
            selectedItem: null,
            itemRow: [],
            page: 0,
            totalRows: 0,
            itemNumber: 0,
            pageNumbers: [],
            showItemModal: false,
            showAddItemModal: false,
            type: '',
            rarity: '',
            name: '',
            isLoaderModal: false,
            scroller:null
        }
    }

    createItemRows() {
        let itemRow = [{ items: [] }];
        let rowCount = 0;
        this.state.items.forEach(item => {
            if (itemRow[rowCount].items.length < 12) {
                itemRow[rowCount].items.push(item);
            } else {
                itemRow.push({ items: [item] });
                rowCount++;
            }
        });
        this.setState({ itemRow: itemRow });
    }
    pageButtons() {
        const itemRows = Math.ceil(this.state.itemNumber / 12)
        for (let i = 0; i <= itemRows; i++) {
            //set the pageNumbers state to an array of html elements for the page buttons
            this.state.pageNumbers.push(<li className={"page-item " + (this.state.page === i ? 'active' : '')} key={i} onClick={() => this.setState({ page: i })}><a className="page-link" >{i + 1}</a></li>);
            // console.log(this.state.pageNumbers)
        }

    }
    //get the page number from the url and set the state to the page number

    //create a  function that will add table rows to the itemRows state or update the state if the item is already in the state
    async listenForChanges() {
        this.setState({ loading: true });
        const uid = userId();
        const q = query(collection(db, "magicItems"), where("userId", "==", uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let items = [];
            querySnapshot.forEach((doc) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            this.setState({ items: items, items_t: items, itemNumber: items.length, loading: false });
            this.createItemRows();
            this.pageButtons();
        });
    


    }
     
    
    //create a function that will return the page number from the url and set the state to the page number
    setPage() {
        const queryParams = new URLSearchParams(window.location.search)
        const page = queryParams.get("page");
        this.setState({ type: null, name: '', rarity: '' })
        if (page && page - 1 <= this.state.totalRows + 1 && page - 1 >= 0) {
            this.setState({ page: page - 1 });
        }
        else {
            this.setState({ page: 0 });
        }
    }
    componentDidMount() {
        //listen for changes in the magic items collection
        this.listenForChanges(this.state.type, this.state.rarity, this.state.name)
        this.setPage();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.type !== this.state.type || prevState.rarity !== this.state.rarity) {
            this.listenForChanges(this.state.type, this.state.rarity, this.state.name)
        }

    }
    editMagicItem = item => {
        this.setState({ selectedItem: item });
    }
    removeMagicItem(id) {
        const confirmDelete = window.confirm("Are you sure you want to delete this magic item?");
        if (confirmDelete) {
            const itemRef = doc(db, `magicItems`, id);
            deleteDoc(itemRef);
            //set the magicItems state to a new array without the deleted magic item
            document.getElementById(id).remove();
        }
    }
    //navigate back a page in the table
    previousPage = () => {
        if (this.state.page > 0) {
            this.setState({ page: this.state.page - 1 });
        }
    }
    //navigate forward a page in the table
    nextPage = () => {
        if (this.state.page < this.state.totalRows - 1) {
            this.setState({ page: this.state.page + 1 });
        }
    }

    handleClose = () => {
        this.setState({ showItemModal: false, selectedItem: null })
    }

    handleCloseCreateModal = (event) => {
        this.setState({ showAddItemModal: false })
    }

    onTypeChange = (event) => {
        this.setState({ type: event.target.value, pageNumbers: [] })
        this.listenForChanges(event.target.value, this.state.rarity, this.state.name)

    }

    onRarityChange = (event) => {
        this.setState({ rarity: event.target.value, pageNumbers: [] })
        this.listenForChanges(this.state.type, event.target.value, this.state.name)
    }

    onNameChange = (event) => {
        
        this.setState({ name: event.target.value, pageNumbers: [] })
        let data = this.state.items_t.filter((item)=>{
            return item.name.toLowerCase().indexOf(event.target.value.toLowerCase())!==-1;
        })
        this.setState({ items: data})
        console.log(data)
       // this.listenForChanges(this.state.type, this.state.rarity, event.target.value)
    }

    onMagicItemCreate = async (magicitem) => {
        this.setState({ isLoaderModal: true })
        let id = await createMagicItem(magicitem);
        this.listenForChanges(this.state.type, this.state.rarity, this.state.name)
        this.setState({ isLoaderModal: false })
    }

    onMagicItemUpdate = async (magicitem) => {
        console.log(magicitem)
        this.setState({ isLoaderModal: true })
        let id = await updateMagicItem(magicitem);
        this.setState({ isLoaderModal: false })
        this.listenForChanges(this.state.type, this.state.rarity, this.state.name)
    }

    //listen for changes in the magic items collection
    //render a table with the magic items in the library
    render() {
        return (
            <div className="contentBody">
                <MagicItemPopup show={this.state.showItemModal} handleClose={this.handleClose} onSubmit={this.onMagicItemUpdate} items={this.state.selectedItem}></MagicItemPopup>
                <MagicItemAdd show={this.state.showAddItemModal} onSubmit={this.onMagicItemCreate} handleClose={this.handleCloseCreateModal}></MagicItemAdd>
                <LoaderModal show={this.state.isLoaderModal}></LoaderModal>
                <div className="filters row">
                    <button type="button" className="create-btn col-2" onClick={() => this.setState({ showAddItemModal: !this.state.showAddItemModal })}>Create</button>
                    <RgpDropDown defaultvalue={'Rarity'} onChange={this.onRarityChange} values={['Common', 'Uncommon', 'Rare', 'Very Rare', 'Legendary', 'Artifact']} classes={[' col-4']}></RgpDropDown>
                    <RgpDropDown defaultvalue={'Type'} onChange={this.onTypeChange} values={['Weapon', 'Ring', 'Wonderous Item', 'Amulet', 'Armor', 'Potion', 'Scroll', 'Wand']} classes={[' col-4']}></RgpDropDown>
                    <div className="inputfield col-2"><input placeholder="Search items by title..." onChange={this.onNameChange}></input></div>
                </div>
                <br></br>


                {/*if the component is loading, display a loading message otherwise display the table*/}
                {this.state.loading ? <p style={{ textAlign: 'center' }}>Loading...</p> :
                <MagicItemList items={this.state.items} page={0} itemNumber={this.state.itemNumber} changePage={(page)=>this.setState({page:page})} handleClose={(item) => { this.setState({ showItemModal: !this.state.showItemModal }); this.setState({ selectedItem: item }) }}></MagicItemList>  
                }

                {/* create a modal to edit the magic item */}
                <Modal id="magicItemModal" isOpen={false} toggle={() => this.setState({ selectedItem: null })}>
                    <ModalHeader toggle={() => this.setState({ selectedItem: null })}>Edit Magic Item</ModalHeader>
                    <ModalBody>
                        {/* if the selected item is not null, display the item's information in the modal */}
                        {this.state.selectedItem !== null ? (
                            <form>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="Name" value={this.state.selectedItem.name} onChange={(e) => this.setState({ selectedItem: { ...this.state.selectedItem, name: e.target.value } })} />
                                    <label htmlFor="rarity">Rarity</label>
                                    <input type="text" className="form-control" id="rarity" placeholder="Rarity" value={this.state.selectedItem.rarity} onChange={(e) => this.setState({ selectedItem: { ...this.state.selectedItem, rarity: e.target.value } })} />
                                    <label htmlFor="type">Type</label>
                                    <input type="text" className="form-control" id="type" placeholder="Type" value={this.state.selectedItem.type} onChange={(e) => this.setState({ selectedItem: { ...this.state.selectedItem, type: e.target.value } })} />
                                    <label htmlFor="magicItem">Description</label>
                                    <textarea className="form-control" id="magicItem" rows="3" placeholder="Magic Item" value={this.state.selectedItem.magicItem} onChange={(e) => this.setState({ selectedItem: { ...this.state.selectedItem, magicItem: e.target.value } })} />
                                </div>
                            </form>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        {/*display a button to save the changes to the magic item and close the modal if successful*/}
                        <Button color="primary" onClick={() => {
                            updateMagicItem(this.state.selectedItem).then(
                                () => this.setState({ selectedItem: null }
                                    //if the update is successful, reload the page to display the updated magic item
                                ));
                        }}>Save</Button>
                        <Button color="secondary" onClick={() => this.setState({ selectedItem: null })}>Cancel</Button>
                    </ModalFooter>
                </Modal>


            </div>
        )
    }
}

