import { initializeApp } from 'firebase/app';
import { getAuth, PhoneAuthProvider } from 'firebase/auth';
import { getFirestore, doc, setDoc,collection,where, query, getDocs,getDoc, addDoc,onSnapshot } from 'firebase/firestore';
import { getStorage, ref, listAll,getDownloadURL} from "firebase/storage";

//intitalize the firebase app
const firebaseConfig = {
    apiKey: "AIzaSyDit6eLGj5lv4NmiAUfZDMZAdlfBrYvdPk",
    authDomain: "rpghookgen.firebaseapp.com",
    projectId: "rpghookgen",
    storageBucket: "rpghookgen.appspot.com",
    messagingSenderId: "441924007135",
    appId: "1:441924007135:web:b86fbeafe7519ca14d656a",
    measurementId: "G-HQCN8HM03J"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


//create a function to update the magic item in the database
async function updateMagicItem(item) {
    const itemRef = doc(db, `magicItems`, item.id);
    const id = await setDoc(itemRef,{
        name: item.name,
        rarity: item.rarity,
        type: item.type,
        magicItem: item.magicItem,
        edited: true,
    }, {merge:true})

}

async function createMagicItem(item) {;
    const id = auth.currentUser.uid;
    const magicItemRef = collection(db, "magicItems");
    const newItem = await addDoc(magicItemRef, {
        name: item.name,
        rarity: item.rarity,
        type: item.type,
        userId: id,
        edited: false,
    });
    return id

}

async function listenForMagicItems(type=null, rarity=null, name=null) {
    const id = userId();
    const magicItemsRef = collection(db, "magicItems");
    const queries = []
    queries.push(where('userId','==',id))
    if(type) queries.push(where('type','==',type?.toLowerCase()))
    if(rarity) queries.push(where('rarity','==',rarity?.toLowerCase()))
    if(name) queries.push(where('name','<',name?.toLowerCase().replace(
        /.$/, c => String.fromCharCode(c.charCodeAt(0) + 1),
      )))
    if(name) queries.push(where('name','>=',name?.toLowerCase()))

    console.log(queries)
    const q = query(magicItemsRef, ...queries);
    console.log(q)
    
    const docs = await getDocs(q);
    return docs;
}
//create a magic item subscription
async function subscribeToMagicItems() {
    const id = userId();
    const magicItemsRef = collection(db, "magicItems");
    const q = query(magicItemsRef, where('userId','==',id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let items = [];
        querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
                items.push(change.doc.data());
            }
            if (change.type === "modified") {
                items.push(change.doc.data());
            }
            if (change.type === "removed") {
                items.push(change.doc.data());
            }
        });
        console.log(items.length);
    });

    return unsubscribe;
}

//create a function that will report the number of magic items in the database for the current user by type and rarity
async function getMagicItemCount() {
    const id = userId();

    const magicItemsRef = collection(db, "magicItems");
    const q = query(magicItemsRef, where("userId", "==", id));
    const docs = await getDocs(q);
    const count = {
        rarity:{
        common: 0,
        uncommon: 0,
        rare: 0,
        "very rare": 0,
        legendary: 0,
        },
        type:{
        weapon: 0,
        armor: 0,
        potion: 0,
        scroll: 0,
        ring: 0,
        wand: 0,
        amulet: 0,
        "wonderous item": 0,
        },
        total: 0,
    };
    docs.forEach(doc => {
        count.rarity[doc.data().rarity]++;
        count.type[doc.data().type]++;
        count.total++;
    }
    );
    console.log(count);
    return count;
}


function userId() {
    return auth.currentUser.uid;
}


function getNPCLibrary() {
    const id = userId();
    const npcLibraryRef = collection(db, "npcs");
    const npcQuery = query(npcLibraryRef,where("userId","==",id))
    const docs = getDocs(npcQuery)
    return docs

}

//import a random image from the resources/images/avatars folder

function getRandomImage() {
    const storage = getStorage(app);
    const listRef = ref(storage, 'avatars');
    const list = listAll(listRef).then((res) => {
        const randomNum = Math.floor(Math.random() * res.items.length);
        const randomImage = res.items[randomNum];
        const url = getDownloadURL(randomImage);
        
        return url;
    });
    
    return list;
}

//create a function to pull an NPC from the database by id and userid
async function getNPC(id) {
    const npcRef = doc(db, "npcs", id);
    const npc = await getDoc(npcRef);
    return npc;
}

//create a function to pull all the images from the storage bucket
async function getImages() {
    const storage = getStorage(app);
    const listRef = ref(storage, 'avatars');
 
    const list = await listAll(listRef).then((res) => {
        let images = [];
        res.items.forEach((itemRef) => {
        const url = getDownloadURL(itemRef);
        images.push(url);
        });
     
        return images;
    });
    return list;
}

export { auth, db, subscribeToMagicItems, userId, updateMagicItem, createMagicItem, listenForMagicItems, getMagicItemCount, getNPCLibrary,getRandomImage, getNPC, getImages };