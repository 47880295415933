//Create a page to display an NPC library

import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, userId } from "../resources/firebase";
import NpcChatPreview from "../components/npcListComponent";
import NpcCreateModal from "../components/npcCreateModal";
import "./npcLibrary.css";

// The NPCs should be displayed like a chat room. The user should be able to click on an NPC to view the NPC's details. The user should be able to delete an NPC from the library.
// The user should be able to search for an NPC by name.
export default function NpcLibrary() {
  const [npcs, setNpcs] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredNpcs, setFilteredNpcs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [npcCount, setNpcCount] = useState(0);
  // get the NPC data
  const getNpcs = async () => {
    const id = userId();
    const npcLibraryRef = collection(db, "npcs");
    const npcQuery = query(npcLibraryRef, where("userId", "==", id));
    const npcSnapshot = onSnapshot(npcQuery, (querySnapshot) => {
      const npcList = [];
      querySnapshot.forEach((doc) => {
        npcList.push({ ...doc.data(), id: doc.id });
      });
      setNpcs(npcList);
      setLoading(false);
      if (npcList.length >= npcCount) {
        setNpcCount(npcList.length);
      }
    });
  };
  useEffect(() => {
    getNpcs().then(() => {
      //sort the NPCs by name alphabetically
      const npcList = npcs.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setFilteredNpcs(npcList);
    });
  }, []);
  useEffect(() => {
    setFilteredNpcs(
      npcs.filter((npc) =>
        npc.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [npcCount, npcs,search]);
  return (
    <div className="content">
      <div className="row">
        <div className="col-md-3 scroll">
          {/* this div is a place to display NPC available to the user as a chat list*/}
          <section className="npc">
            <div className="npc">
              {/*Create a serach bar*/}
              <div className="searchBar">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="search form-control input-sm"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  ></input>
                  </div>
              </div>
              {/* add a loading icon while the page is loading and display the NpcChatPreview for each NPC in the state when loading is finished*/}
              {loading ? (
                <div className="loading"> loading</div>
              ) : (
                filteredNpcs.map((npc) => {
                  return (
                    <NpcChatPreview name={npc.name} id={npc.id} key={npc.id} />
                  );
                })
              )}
            </div>
          </section>
        </div>
        <div className="col-md-9">
          {/* this div is a place to display the NPC details and a create NPC button*/}
          <div className="row">
            <div className="col-md-12">
              <NpcCreateModal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
