//create a component that displays the instructions for the page. Themed for D&D 5e. 
// This needs to take props for the instructions and the title

import React from 'react';
import './instructionCard.css';

export default function InstructionCard(props) {
    return (
        <div className="instruction-box">
            <div className="instruction-box-inner">
                <h5 className="title">{props.title}</h5>
                <p className="text">{props.instructions}</p>
            </div>
        </div>
    );
}