

import { useEffect, useState } from 'react'
import './formfields.css'
const RgpDropDown = ({ values, classes, defaultvalue, selectedValue, onChange }) => {
    const [dropValue, setDropValue] = useState(selectedValue);

    useEffect(() => {
        setDropValue(selectedValue);
    }, [selectedValue])

    return (
        <select className={"selectors " + (classes?.join(" "))} onChange={onChange}>
            <option value={''}>{defaultvalue}</option>
            {values.map((item) => {
                return <option selected={dropValue == item?.toLowerCase()} value={item}>{item}</option>
            })}
        </select>
    )
}

export default RgpDropDown;