//Create a home page with three pictures that link to the magic item generator, the magic item library, and the npc generator

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import "./home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    return (
      <>
        <div className="row" id="generators">
          <div className="col-md-6">
            <Link to="/magic-items">
              <div className="box" id="magicItem">
                <div className="box-inner">
                  <div className="wraper">
                    <h6 className="shadow">Item Generator</h6>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/npc-generator">
              <div className="box" id="NPC">
                <div className="box-inner">
                  <div className="wraper">
                    <h6 className="shadow">NPC Backstory</h6>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row" id="card-items">
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Card title</h5>
                        <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div>
        </div>
        </div>
      </>
    );
  }
}
