import { toast } from "react-toastify"


const Validation = (inputField) => {

    let errors = {}

    if (!inputField.email) {
        errors.email = "**Email is required."
    }
    else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(inputField.email)) {
        errors.email = "** Email is Invalid"
    }
    if (!inputField.password) {
        errors.password = "**Password is required"
    }
   
     if(inputField.email && inputField.password && inputField.confirmPassword) {
        toast.success(" Login Successfully ")
    }
    console.log("---->",errors)

    if(inputField.email && inputField.password ) {
        toast.success(" Login Successfully ")
    }

    return errors


}
export default Validation;