//create a chat message list to display the NPC, NPV Avatar, and the NPC's message preview

import React, {useState, useEffect}from 'react';

import './npcListComponent.css';
import { getRandomImage } from '../resources/firebase';


//create a component
export default function NpcChatPreview(props) {
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(true);

    //create a function to return a random photo from the resources/images/avatar folder
    const getAvatar = async () => {
        const img = await getRandomImage();
        setImage(img);
        setLoading(false);
    }
    useEffect(() => {
        getAvatar();
    }, []);
    return (
        <div className="npc chat row" key={props.key}>
            <div className="col-md-2">
            <div className="npc avatar">
                <img src={image} alt="npc avatar" className='avatar'/>
            </div>
            </div>
            <div className='col-md-1'></div>
            <div className="col-md-9">
            <div className="npc name">
                <p>{props.name}</p>
            </div>
            <div className="npc message">
                <span className='lightText'>{props.msg}</span>
            </div>
            </div>
        </div>
    )
}