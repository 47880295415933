//This React component will allow this user to generate a NPC by entering a name and flavor text. The flavor text will be used to generate the background of the NPC. The name will be used to generate the name of the NPC. The user will be able to edit the name and background of the NPC after it is generated. The user will also be able to save the NPC to the database.

import React, { useState, useEffect } from "react";
import { userId } from "../resources/firebase";
import InstructionCard from "../components/instructionCard";

export default function NPCGenerator() {
    {/* set variables for Race: Short Text, Age: Numeric, Class: Multiple Choice, Alignment: Multiple Choice, Occupation: Short Text, Background: Long Text*/}
    const [name, setName] = useState("");
    const [flavorText, setFlavorText] = useState("");
    const [background, setBackground] = useState([]);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState("new");
    const [buttonText, setButtonText] = useState("Generate NPC");
    const [isNew, setIsNew] = useState(true);
    const [race, setRace] = useState("");
    const [charcterClass, setCharcterClass] = useState("");
    const uid = userId();
    const instructions = "Enter a name and a short description of the NPC. Our AI will generate some information for the NPC. You can edit the information and ask for more later.";

    //create a function to handle the submit event and submit the name and flavor text to https://us-central1-rpghookgen.cloudfunctions.net/createNPC
    function handleSubmit(event) {
        event.preventDefault();
        //send the name and flavor text to the url as a GET request and display the response in the form
        setLoading(true);
        let submitText = ""
        background.forEach((item) => {
            submitText += item + " ";
        });
         submitText += flavorText;
        //update the background state to combine the background and flavor text
        
        fetch(`https://us-central1-rpghookgen.cloudfunctions.net/createNPC?name=${name}&flavorText=${submitText}&userId=${uid}&id=${id}&question=${flavorText}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setBackground([...background,flavorText,data.background]);
                setId(data.id);
                
            }).catch(err => {
                console.log(err);
            }
            );
    }
    useEffect(() => {
        if (isNew) {
            setButtonText("Update NPC");
            setIsNew(false);
        }
        background.forEach((item) => {
            let html = `<p> ${item} </p>`
            document.getElementById("background").insertAdjacentHTML("beforeend", html);
        });
        setLoading(false);
    }, [background,buttonText]);
    //create a function to clear the form, the background, and the text in the background div
    function clearForm() {
        setName("");
        setFlavorText("");
        setBackground([]);
        document.getElementById("background").innerHTML = "";
        setButtonText("Generate NPC");
    }



    //create a form component with fields for the name(text field) and flavor text(text field)
    return (
        <div className="npcGenerator">
            <div className="row">
                <div className="col-12">
                    <InstructionCard title="NPC Generator" instructions={instructions}/>
                </div>
            </div>
            <div className="row content-area">
                <div className="col-12">
            <h2 className="content-area">Create a NPC</h2>
            <form onSubmit={handleSubmit}>
                {/* create fields for Name(Text), Race(Text), Age(Numeric), Class(Multiple Choice), Alignment(Multiple Choice),Occupation(Text), Background(Textarea)*/}
                <div className="form-row">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" value={name} onChange={(event) => setName(event.target.value)} />

                        <div className="form-group col-md-5">
                            <label htmlFor="Race">Race</label>
                            <input type="text" className="form-control" id="race" value={race} onChange={(event)=> setRace(event.target.value)} />
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="class">Class</label>
                            <input type="text" className="form-control" id="class" value={charcterClass} onChange={(event)=> setCharcterClass(event.target.value)} />
                        </div>
                    </div>        

                <div className="form-group">
                    <label htmlFor="flavorText">Flavor Text</label>
                    <textarea className="form-control" id="flavorText" rows="3" value={flavorText} onChange={(event) => setFlavorText(event.target.value)}></textarea>
                </div>
                <button type="submit" className="btn btn-primary">{buttonText}</button>
                {/*create a button to clear the background and flavor text*/}
                <button type="button" className="btn btn-warn" onClick={() => { clearForm()}}>Clear</button>
            </form>  
                </div>
            </div>
            {/*display a loading spinner while loading is true*/}
            {loading ? <div className="spinner-border text-primary" role="status">
            </div> : null}
            {/*display the background text in a div*/}
            <div className="row content-area">
                <div className="col-12">
                    <h2 className="content-area">Background</h2>
                    <div id="background"></div>
                    </div>
                    </div>
        </div>
    );
}
