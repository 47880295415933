import { useEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import RgpDropDown from "../../components/formfields/dropdown";
import './loadermodal.css';
import logo from "../../resources/images/logo-big.svg";

function useOutsideAlerter(ref, toggleCallback, handleClose) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleCallback(false)
              //  handleClose(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const LoaderModal = ({show, handleClose }) => {
    const [showModal, setShowModal] = useState(false);
    const [isEditMode, setisEditMode] = useState(false);
    const wrapperRef = useRef(null);
   // useOutsideAlerter(wrapperRef, setShowModal, handleClose);
    useEffect(() => {
        setShowModal(show)
        console.log(showModal, show)
    }, [show])

    return (
        <div ref={wrapperRef} className={"app-modal " + (showModal ? "display-block" : "display-none")} style={{ background: '#F2F4F3' }}>
            <div className="modal-container">
                <div className="row" >
                    <img src={logo} style={{height:'300px', paddingTop:'30px'}}></img>
                </div>
                <p className="loader-text">Loading....</p>

                <div className="middle-ads-container ad-container" > <h2>Ad Space</h2> </div>
            </div>

        </div>
    );
}

export default LoaderModal