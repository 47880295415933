import { Outlet } from "react-router-dom";
import NavbarComponent from "../components/navbar";
import { auth } from "../resources/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Login from "./LoginSignup";
import { useState, useEffect } from "react";
import './Layout.css';

//create a layout component to display the navbar and the content of the page
export default function Layout() {
    //set a state to check if the user is logged in
    const [loginState, setLoginState] = useState(false);
    //create a function to check if the user is logged in
    useEffect(() => {
        onAuthStateChanged(auth, user => {
            if (user.exists) {
                setLoginState(true);
            } else {
                setLoginState(false);
            }
        });
    }, [loginState]);

    return (
        <div className="layout">
            <div className="row nav-area">
                <div className="col-md-12">
                    <NavbarComponent />
                </div>
            </div>
            <div className="row">
                
                <div className="col-md-1"> </div>
                <div className="container main col-md-8">
                <div className="middle-ads-container" > <h2>Ad Space</h2></div>

                    {/* if the user is logged in, display the content of the page  else display login page*/}
                    <Outlet />
                </div>
                <div className="col-md-1"> </div>
            </div>
        </div>
    );
}